/* TopBar */
.dealer-topbar {
    background-color: rgb(32, 56, 77);
    position: fixed;
    height: 66px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
}

.sidebar {
    height: 100vh;
    position: fixed !important;
    bottom: 0px !important;
    top: 0px !important;
    overflow-x: hidden;
    /*padding-top: 70px;*/
    padding-top: 0px;
    z-index: 3 !important;
    background-color: #40434E;
    min-width: 170px;
}

.sidebarNav:hover {
    background-color: #33353e !important;
}

.ui.thin.left.sidebar {
    width: 170px;
}

.ui.push.sidebar {
    z-index: 3;
}

.ui.slide.along.sidebar {
    z-index: 3;
}

.ui.overlay.sidebar {
    z-index: 3;
}

.ui.pointing.dropdown > .menu:after {
    background-color: #40434E;
}

.dropdownHeader {
    margin: 0px !important;
    padding: 0px !important;
    color: white !important;
    text-transform: none !important;
}

.ui.dropdown .menu > .header {
    font-size: 16px;
}
