.my-farm-to-table {
  color: white !important;
}

.altRow {
  background-color: #27425C !important;
}

.my-farm-to-table.ui.table thead th {
  color: white !important;
  border-bottom: thin solid #CCC !important;
}

#mytable.ui.selectable.table tbody tr:hover, .ui.table tbody tr td.selectable:hover {
  color: lightblue !important ;
  background: rgba(255,255,255,.1)!important;
}
/*#mytable > tbody > tr:nth-child(1) > td.center.aligned.one.wide.nopadding*/
#mytable > tbody > tr > td.center.aligned.one.wide.nopadding {
  padding: 0px;
}

.negative1 {
  color: #ff7305 !important;
}

.positive1 {
  color: #bff993 !important;
}


.contractSelect .ui.table thead th {
  color: black !important;
  border-bottom: thin solid #333 !important;
}

.popup .ui.table thead th {
  color: black !important;
  border-bottom: thin solid #333 !important;
}

td input {
  border-radius: 0px;
}
