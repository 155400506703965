.StripeField,
.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 18px;
    color: #9F9F9F;

    font-weight: 500;
    font-family: Open Sans, Helvetica Neue, Helvetica, sans-serif;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px, rgba(0, 0, 0, 0.0196078) 0 1px 0;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0 4px 6px, rgba(0, 0, 0, 0.0784314) 0 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}
.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}


.errorMsg {
    font-size: 12px;
    color: orangered;
}


.loaderCRMExtension {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #4B4B4B; /* Dark grey */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
