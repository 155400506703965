.ui.attached.success.message, .ui.success.message {
    box-shadow: 0 0 0 1px lightskyblue !important;
}

i.icon.checkmark:before {
    color: green !important;
}

.ui.success.message .header {
    color: black !important;
}

.ui.message .header+p {
    color: black !important;
}