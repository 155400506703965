$darker-blue: #26384b;

.upload-modal {
    font-family: 'Roboto', sans-serif;
    position: relative;
    background-color: #e9ecef;

    &__button-cancel {
        width: 35%;
        padding: 15px;
        margin: 15px;
        font-size: 20px;
        color: $darker-blue;
        border: solid 2px $darker-blue;
        border-radius: 10px;
        background-color: #e9ecef;
    }

    &__button-close {
        width:30px;
        height:30px;
        position: absolute;
        top: 15px;
        right: 15px;
        border-radius: 30px;
    }

    &__button-retry {
        width: 35%;
        padding: 15px;
        margin: 15px;
        font-size: 20px;
        background-color: $darker-blue;
        color: white;
        border: solid 2px $darker-blue;
        border-radius: 10px;
    }

    &__button-row {
        width: 100%;
    }

    &__progress-bar {
        height: 5%;
        width: 40%;
        background-color: #fafafa;
        border: none;
        position: absolute;
        margin: 0px 0px 40px 10px;

        &::-webkit-progress-value {
            background: $darker-blue;
            padding: 5px;
            border-radius: 10px;
            background-size: 30px 30px;
            background-image: linear-gradient(45deg, rgba(255, 255, 255, .10) 25%, transparent 25%,
            transparent 50%, rgba(255, 255, 255, .10) 50%, rgba(255, 255, 255, .10) 75%,
            transparent 75%, transparent);
        }

        &::-webkit-progress-bar {
            background: #ebebeb;
            border-radius: 10px;
        }
    }

    &__graphic {
        width: 90%;
        margin: 0 0 20px 0;
        align-items: center;

        &--fail {
            width: 50%;
            align-items: center;
        }
    }

    &__text-fail {
        margin-bottom: 5px;
        font-size: 22px;
    }

    &__text-fail-tips {
        margin-bottom: 10px;
        color: $darker-blue;

        &>li {
            list-style-type: square;
        }
    }

    &__text-please-wait {
        color: $darker-blue;
        font-size: 14px;
        position: absolute;
        margin: 5% 0 0 10px;

    }

    &__text-upload-in-progress {
        text-align: center;
        font-size: 22px;
        color: $darker-blue;
        margin: 10px;
    }
}
