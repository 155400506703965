.pig-biplane {
  position: absolute;
  top: 15%;
  left: -200px;
  z-index: 10;

  animation-duration: 15s;
  animation-name: goRight;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -moz-animation-duration: 15s;
  -moz-animation-name: goRight;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
}

.pig {
  animation-duration: 2s;
  animation-name: upDown;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;

  -moz-animation-duration: 2s;
  -moz-animation-name: upDown;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-direction: alternate;
}

@keyframes upDown {
  to { transform: translatey(100px);}
}

@keyframes goRight {
  from {
    -webkit-transform: translate(0,0);
  }

  to {
    -webkit-transform: translate(1920px,0);
  }
}
