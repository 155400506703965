/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

/*@font-face {*/
/*  font-family: Ubuntu-Regular;*/
/*  src: url('fonts/ubuntu/Ubuntu-Regular.ttf');*/
/*}*/

/*@font-face {*/
/*  font-family: Ubuntu-Bold;*/
/*  src: url('fonts/ubuntu/Ubuntu-Bold.ttf');*/
/*}*/

/*---------------------------------------------*/
a.light {
    /*font-family: Ubuntu-Regular;*/
    font-size: 14px;
    line-height: 1.7;
    color: lightblue;
    margin: 0;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a.light:focus {
    outline: none !important;
}

a.light:hover {
    text-decoration: none;
    color: #8bb1bf;
}

/*---------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
    margin: 0px;
}

p {
    /*font-family: Ubuntu-Regular;*/
    font-size: 14px;
    line-height: 1.7;
    color: black;
    margin: 0px;
}

ul, li {
    margin: 0px;
    list-style-type: none;
}


/*---------------------------------------------*/
input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus, input:focus {
    /*border-color: transparent !important;*/
}

input::-webkit-input-placeholder {
    color: #8f8fa1;
}

input:-moz-placeholder {
    color: #8f8fa1;
}

input::-moz-placeholder {
    color: #8f8fa1;
}

input:-ms-input-placeholder {
    color: #8f8fa1;
}

textarea::-webkit-input-placeholder {
    color: #8f8fa1;
}

textarea:-moz-placeholder {
    color: #8f8fa1;
}

textarea::-moz-placeholder {
    color: #8f8fa1;
}

textarea:-ms-input-placeholder {
    color: #8f8fa1;
}

/*---------------------------------------------*/
button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;;
}


.wrap-login100 {
    width: 390px;
    background: #fff;
    border-radius: 10px;
    position: relative;
}


/*==================================================================
[ Form ]*/

.login100-form {
    max-width: 400px;
    width: 100%;
}

.login100-form-title {
    /*font-family: Ubuntu-Bold;*/
    font-size: 30px;
    color: lightblue;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;

    width: 100%;
    display: block;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    background-color: #e6e6e6;
    border: 1px solid transparent;
    border-radius: 3px;
}


/*---------------------------------------------*/
.input100 {
    /*font-family: Ubuntu-Bold;*/
    color: black;
    line-height: 1.2;
    font-size: 18px;

    display: block;
    width: calc(100% - 58px);
    background: transparent;
    height: 62px;
    padding: 0 20px 0 38px;
}

.input101 {
    width: 100%;
    /*font-family: Ubuntu-Bold;*/
    color: black;
    line-height: 1.2;
    font-size: 16px;

    display: block;
    background: transparent;
    height: 40px;
    padding: 0 20px 0 38px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.login100-form-btn {
    /*font-family: Ubuntu-Bold;*/
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 62px;
    background-color: #3B5A74;
    border-radius: 3px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background-color: #27425C;
}


/*==================================================================
[ Restyle Checkbox ]*/

.input-checkbox100 {
    display: none;
}

.label-checkbox100 {
    /*font-family: Ubuntu-Regular;*/
    font-size: 16px;
    color: lightblue;
    line-height: 1.2;

    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
}

.label-checkbox100:hover {
    color: #3B5A74;
}

.label-checkbox100::before {
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 13px;
    color: transparent;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    background: #fff;
    border: 2px solid #3B5A74;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
    color: #3B5A74;
}
